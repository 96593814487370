import { FormErrorMessage, Select, FormControl, FormLabel, Input, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'

export const SelectField = React.forwardRef((props, ref) => {
  const { liste, label, errors, isRequired, reference, name, ...rest } = props
  return (
    <FormControl  position="relative" isInvalid={errors[name]}>
      <FormLabel
        _focus={{
          color: mode('brand.primary', 'brand.primary'),
        }}
        fontWeight="semibold"
        position="absolute"
        color={mode('gray.600', 'white')}
        top="-7"
        // insetStart="2"
        // bg={{
        //   base: mode('white', 'gray.800'),
        //   md: mode('white', 'gray.700'),
        // }}
        zIndex={2}
        px="2"
        fontSize={{base:"sm", md:"md"}}
      >
        {!isRequired? label: `${label} *`}
      </FormLabel>
      <Select
        placeholder={`Selectionner ${label}`}
        ref={reference}
        size="md" 
        focusBorderColor="brand.secondary" 
        fontSize="md" {...rest} 
        name={name}
      >
        {liste.map((item, key) => {
          return (
            <option value={item.key} >{item.value}</option>
          )
        })
        }
      </Select>
      <FormErrorMessage>
        {errors[name]?.message}
      </FormErrorMessage>
    </FormControl>
  )
})
SelectField.displayName = 'SelectField'
