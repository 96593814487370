import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { GlobalContext } from "./context";
import Accueil from "./pages/accueil";
import Administration from "./pages/Administration";
import  Home  from "./pages/home";
import { Login } from "./pages/login";
import Parametrage from "./pages/Parametrage";
import { HomeSista } from "./pages/sista";
import GestionChambre from './pages/GestionChambre'
import GestionReservation from "./pages/GestionReservation";

// 2. Update the breakpoints as key-value pairs

const RouteApp = () => {
  //const [token, setToken] = useState(null)

  const context = useContext(GlobalContext);
  const [isReload, setIsReload] = useState(false)

  useEffect(() => {
 context.retrieve()
 setIsReload(!isReload)

  }, []);
  
  useEffect(() => {
  console.log(context.token)
  }, [isReload]);

  return (
    <>
      <BrowserRouter>
        <Switch>
        <Route exact path="/">
          <Accueil/>
          </Route>
          <Route exact path="/sista">
          <HomeSista/>
          </Route>
          <Route exact path="/login">
             <Login />
          </Route>
          <Route exact path="/home">
             {context.token? <Home /> :<Login/> }
          </Route>
          <Route exact path="/administration">
             {context.token? <Administration /> :<Login/> }
          </Route>
          <Route exact path="/parametrage">
             {context.token? <Parametrage /> :<Login/> }
          </Route>
          <Route exact path="/reservation">
             {context.token? <GestionReservation /> :<Login/> }
          </Route>
          <Route exact path="/chambre">
             {context.token? <GestionChambre /> :<Login/> }
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default RouteApp;