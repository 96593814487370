import axios from 'axios';

const API_PATH = "http://2asoeurs.com/dev-api";
export default class APIClient {

    constructor(token, logout) {
        console.log("constructor====>", token)
        this.client = axios.create({
            baseURL: API_PATH,
            timeout: 300000,
            headers: {
                'X-CSRF-TOKEN': '',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            config: {
                'withCredentials': true,
                'crossOrigin': true,
            }

        });
        this.logout = logout
    }

    static instance(token, logout) {
        console.log("instance====>", token)
        if ((!APIClient.hasOwnProperty('INSTANCE')) || (!APIClient.INSTANCE instanceof APIClient))
            APIClient.INSTANCE = new APIClient(token, logout);
        return APIClient.INSTANCE;
    }

    payload(loading, dismissible, message, autoDismiss, color, action) {
        return {
            loading: loading,
            dismissible: dismissible,
            message: message,
            autoDismiss: autoDismiss,
            color: color,
            action: action
        };
    }

    async post(token = null, url, data, successCallback, errorCallback) {
        this.client.post(url, JSON.stringify(data), {
            headers: {
                'X-CSRF-TOKEN': '',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

            }
        })
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    successCallback(response);
                } else {
                    if (errorCallback != null) errorCallback(response);
                }
            })
            .catch(e => {
                console.log(e);
                if (errorCallback != null) errorCallback(e);
            });
    }
    async postFile(token = null, url, data, successCallback, errorCallback) {
        this.client.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.status === 201) {
                    successCallback(response);
                }
                else {
                    if (errorCallback != null) errorCallback(response);
                }
            })
            .catch(e => {
                console.log(e);
                if (errorCallback != null) errorCallback(e);
            });
    }

    async put(token = null, url, data, successCallback, errorCallback) {
        this.client.put(url, data, {
            headers: {
                'X-CSRF-TOKEN': '',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    successCallback(response);
                }
                else {
                    if (errorCallback != null) errorCallback(response);
                }
            })
            .catch(e => {
                console.log(e);
                if (errorCallback != null) errorCallback(e);
            });
    }

    async get(token = null, url, successCallback, errorCallback) {
        this.client.get(url, {
            headers: {
                'X-CSRF-TOKEN': '',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {

                if (response.status === 200) {
                    successCallback(response);
                } else {
                    if (errorCallback != null) errorCallback(response);
                }
            })
            .catch(e => {
                console.log("errorr", e);
                if (errorCallback != null) errorCallback(e);
            });
    }

    async delete(token = null, url, successCallback, errorCallback) {
        this.client.delete(url, {
            headers: {
                'X-CSRF-TOKEN': '',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {

                if (response.status === 204) {
                    successCallback(response);
                } if (response.status === 401) {
                    this.logout()
                } else {
                    if (errorCallback != null) errorCallback(response);
                }
            })
            .catch(e => {
                console.log(e);
                if (errorCallback != null) errorCallback(e);
            });
    }
}

