import React, { Component } from "react";
import { Box, Container, Text, useToast, Flex, Fade, useDisclosure, ScaleFade, Slide, SlideFade, useColorModeValue as mode } from '@chakra-ui/react'
import { useEffect, useContext } from 'react'
import { Liste } from "./liste";
import Form from "./Form";
import Datatable from "./Datatable";
import { BsListCheck } from "react-icons/bs";
import { FaWpforms } from "react-icons/fa";
import { GlobalContext } from "../../../context";

const CRUD = ({
  columns,
  champs,
  schema,
  path,
  title,
  icon,
  pathGet,
  getItemSelected,
  buttonLabel,
  getData,
  updateChamps
}) => {
  const { isOpen, onToggle } = useDisclosure()
  const [data, setData] = React.useState([])
  const [ item, setItem] = React.useState({})
  const [refresh, setRefresh] = React.useState(false)
  const getRefresh = () => setRefresh(!refresh)
  const context = useContext(GlobalContext)
  const apiService = context.service
  const { service, token, dataUser, logout } = context
  const toast = useToast()

  const onSave = async (data, setData, isUpdate) => {

    if (!isUpdate) {
      await apiService.create(token, path, data, (resp) => {
        console.log("Create")
        onGet(setData)


      },
        (error) => {
          if (error?.response?.status === 401) {
            logout()

          }
          console.error(error);

        })
    }
    else {
      const { id, tableData,url,image, ...restData } = data
      await apiService.update(token, path, id, restData, (resp) => {
        onGet(setData)

      },
        (error) => {
          if (error?.response?.status === 401) {
            logout()

          }
          console.error(error);

        })
    }

  }

  const onDelete = async (data, setData) => {
    const { id } = data
    await apiService.delete(token, path, id, (resp) => {
      onGet(setData)

    },
      (error) => {
        if (error?.response?.status === 401) {
          logout()

        }
        console.error(error);

      })

  }
  // const onGet = async (setData) => {
  //   // setData(data)
  //   const pathGood=pathGet?pathGet:path
  //   await apiService.getAll(token, path, (resp) => {
  //     setData(resp.data.data)
  //     getData(resp.data.data)
  //   },
  //     (error) => {
  //       if (error?.response?.status === 401) {
  //         logout()

  //       }
  //       console.error("error====>", error);
  //     })

  // }
  const onGet = (query, resolve) => {
      let url=path
      let data={
        data: [],
        page: 0,
        totalCount: 0,
      }
      if (query.pageSize) {
        url += '?limit=' + query.pageSize
        url += '&offset=' + (query.page? query.page*query.pageSize: 0)
      }
      apiService.getAll(token, url, (resp) => {
        data={
          data: resp.data.data,
          page: query.page,
          totalCount: resp.data.pagination.total - 1,
        }
        resolve(data)
        getData(resp.data.data)
      },
        (error) => {
          if (error?.response?.status === 401) {
            logout()

          }
          console.error("error====>", error);
        })
  }
  useEffect(() => {
    if (getItemSelected) {
      getItemSelected(item)
    }

  }, [data, item])
  useEffect(() => {
    console.log(path)
    // onGet(setData)

    setItem({})

  }, [])
  return (
    <>


      {isOpen &&

        <SlideFade in={isOpen} offsetY="20px">
          <ScaleFade initialScale={0.9} in={isOpen}>
            {/* <Box minW={{ base: "full", md: "100%", lg: "90%" }}> */}
            <Form
              schema={schema}
              champsInitial={champs}
              columns={2}
              isOpen={true}
              onToggle={onToggle}
              onSave={onSave}
              item={item}
              setRefresh={getRefresh}
              buttonLabel={buttonLabel}
              title={"Formulaire " + title}
              onDelete={onDelete}
              setData={setData}
              setItem={setItem}
              updateChamps={updateChamps}
              icon={<FaWpforms />}

            />
            {/* </Box> */}
          </ScaleFade>
        </SlideFade>
      }
      {!isOpen &&
        <SlideFade in={!isOpen} offsetY='20px' >
          <ScaleFade initialScale={0.9} in={!isOpen}>
            {/* <Box minW={{ base: "full", md: "6xl" }}> */}
            <Datatable
              icon={<BsListCheck />}
              title={"Liste " + title}
              data={data}
              onGet={onGet}
              onSave={onSave}
              onDelete={onDelete}
              columns={columns}
              setData={setData}
              setItem={setItem}
              onToggle={onToggle}
            />
            {/* </Box> */}
          </ScaleFade>
        </SlideFade>
      }

    </>
  )
}
export default CRUD
