import React, { ReactNode } from 'react';
import {
    IconButton,
    Avatar,
    Box,
    CloseButton,
    Flex,
    HStack,
    VStack,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    useColorMode,
    Button,
    Image,
    Heading
} from '@chakra-ui/react';
import {
    FiHome,
    FiTrendingUp,
    FiCompass,
    FiStar,
    FiSettings,
    FiMenu,
    FiBell,
    FiChevronDown,
} from 'react-icons/fi';
import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    SunIcon,
    MoonIcon
} from '@chakra-ui/icons';
import { IconType } from 'react-icons';
import { GlobalContext } from '../../context';
import Logo from '../media/images/logo.png'
import Footer from '../footer';


const LinkItems = [
    { name: 'Home', icon: FiHome, href: "/Home" },
    { name: 'Reservations', icon: FiSettings, href: "/reservation" },
    { name: 'Chambres', icon: FiSettings, href: "/chambre" },
    { name: 'Personnels', icon: FiSettings, href: "/parametrage" },
    { name: 'Administration', icon: FiTrendingUp, href: "/administration" },
    { name: 'Parametrage', icon: FiSettings, href: "/parametrage" },
    { name: 'Site web', icon: FiStar, href: "/" },
];

export default function SidebarWithHeader({
    children,
}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <Box minH="100vh" as="section" minW={{ base: "full", md: "100%", lg: "90%" }}>
            <SidebarContent
                onClose={() => onClose}
                display={{ base: 'none', md: 'block' }}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={onClose} />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav onOpen={onOpen} />
            <Box w={{base:"100%", md:"80%"}} ml={{ base: 30, md: 60 }} >
                {children}
            </Box>
            {/* <Footer/> */}
        </Box>
    );
}



const SidebarContent = ({ onClose, ...rest }) => {
    return (
        <Box
            transition="3s ease"
            // bg={useColorModeValue('brand.primary', 'brand.primary')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            // p={4}
            {...rest}>
            <Box
            bg={useColorModeValue('brand.primary', 'brand.primary')}
            h="16" 
            alignItems="center" 
            justifyContent="space-between"
            w='full'
            p={5}
            mb={20}
            >
                {/* <Image
                    boxSize='50px'
                    objectFit='cover'
                    src={Logo}
                    alt='Insta logo'
                    mx={2}
                /> */}
                <Heading
                   
                    // textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
                    //fontFamily={'heading'}
                    color={"white"}
                    fontWeight="800"
                    as='h3' 
                    size='md'
                    // color={useColorModeValue('brand.primary', 'brand.primary')}

                >
                    GES'2ASOEURS
                </Heading>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Box>
            {LinkItems.map((link) => (
                <NavItem key={link.name} icon={link.icon} href={link.href}>
                    {link.name}
                </NavItem>
            ))}
        </Box>
    );
};


const NavItem = ({ icon, children, href, ...rest }) => {
    return (
        <Link href={href} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="2"
                mb="2"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                bg={useColorModeValue('brand.primary', 'brand.primary')}
                color="white"
                _hover={{
                    color: useColorModeValue('brand.primary', 'brand.primary'),
                    bg: 'white',
                }}
                {...rest}
                fontSize="md"
                >
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="20"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};

const MobileNav = ({ onOpen, ...rest }) => {
    const context = React.useContext(GlobalContext)
    const { dataUser } = context
    const { colorMode, toggleColorMode } = useColorMode();
    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 2, md: 2 }}
            height="16"
            alignItems="center"
            bg={useColorModeValue('brand.primary', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            {...rest}>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
                color='white'
            />

            <Text
                display={{ base: 'flex', md: 'none' }}
                fontSize="2xl"
                fontFamily="monospace"
                fontWeight="bold"
                color='white'
                >
                GES'2ASOEURS
            </Text>

            <HStack spacing={{ base: '0', md: '6' }}>
                {/* <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        /> */}
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}>
                            <HStack>
                                <Avatar
                                    size={'sm'}
                                    src={
                                        'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                                    }
                                />

                                <Box lineHeight="1" px="2">
                                    <Text fontWeight="semibold" fontSize="sm">{dataUser?.user?.prenom} {dataUser?.user?.nom}</Text>
                                    <Text mt="1" fontSize="xs" color="gray.500">
                                        {dataUser?.user?.email}
                                    </Text>
                                </Box>

                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            bg={useColorModeValue('white', 'gray.900')}
                            borderColor={useColorModeValue('gray.200', 'gray.700')}>
                            <MenuItem>Profile</MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={() => { context.logout(); }}>Deconnexion</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
                <Button onClick={toggleColorMode}>
                    {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                </Button>
            </HStack>
        </Flex>
    );
};
