import {
    Card,
    CardContent,
    CardHeader,
    Grid,
} from '@material-ui/core'
import React from 'react'
import Typography from "@material-ui/core/Typography";
import { Spacer, Box, Button, Flex } from '@chakra-ui/react';


const CardForm = ({ cardItem, children }) => {


    return (
        <>
            <Box
                minW={{ base: "full", md: "100%", lg: "90%" }}
                p={10}

            >
                {cardItem?.libelle &&
                    <Flex>
                        <Typography style={{ color: "brand.primary" }} className="filterTitle"/>
                    {/* <Box> {cardItem.icon} </Box><Spacer /><Box>{cardItem.libelle}</Box> */}
                        <Button
                        bgColor={ "#F5F6F7"}
                        color="brand.primary"
                        leftIcon={cardItem.icon}
                        >
                    {cardItem.libelle}
                        </Button>
                    </Flex>

                }
                <Grid spacing={2} container>
                    <Box py={5}>
                        {children}
                    </Box>
                </Grid>
            </Box>

        </>
    )
}

export default CardForm