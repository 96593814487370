import {
    Container,
    Box,
    chakra,
    Flex,
    Text,
    Stack,
    Avatar,
    useColorModeValue,
    SimpleGrid
  } from '@chakra-ui/react';
  
  interface TestimonialAttributes {
    name: string;
    position: string;
    company: string;
    content: string;
    image: string;
  }
  
  const testimonials: TestimonialAttributes[] = [
    {
      name: 'Ben Parker',
      position: 'CEO',
      company: 'Olpers',
      image:
        'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&auto=format&fit=crop&w=334&q=80',
      content: `Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit
        rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam,
        risus at semper`
    },
    {
      name: 'Jena Karlis',
      position: 'GM',
      company: 'Olpers',
      image:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=334&q=80',
      content: `Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit
        rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam,
        risus at semper`
    },
    {
      name: 'Vicky Hald',
      position: 'CFO',
      company: 'Olpers',
      image:
        'https://images.unsplash.com/photo-1599566150163-29194dcaad36?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&auto=format&fit=crop&w=334&q=80',
      content: `Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit
        rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam,
        risus at semper`
    },
    {
      name: 'Vicky Hald',
      position: 'CFO',
      company: 'Olpers',
      image:
        'https://images.unsplash.com/photo-1606513542745-97629752a13b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80',
      content: `Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit
        rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam,
        risus at semper`
    }
  ];
  
  const Clients = () => {
    return (
      <Container minW="full" p={{ base: 5, md: 5 }} bg='brand.primary'>
        <SimpleGrid columns={{ base: 1, md: 2 }} placeItems="center" spacing={1} mt={12} mb={4}>
          {testimonials.map((obj, index) => (
            <Stack key={index} direction="column" mb={5} spacing={0} alignItems="baseline">
              <Stack
                maxW="500px"
                spacing={5}
                mb={10}
                boxShadow="lg"
                rounded="md"
                p={6}
                pos="relative"
                bg='white'
                transform="rotate(-3deg)"
                _after={{
                  content: `""`,
                  borderColor: `brand.primary transparent`,
                  borderStyle: 'solid',
                  borderWidth: '25px 30px 0 0',
                  position: 'absolute',
                  bottom: '-25px',
                  left: '40px',
                  display: 'block'
                }}
              >
                <Box
                  position="relative"
                  rounded="md"
                  transform="rotate(4deg)"
                  _before={{
                    content: '""',
                    bg: 'brand.secondary',
                    filter: 'blur(55px)',
                    position: 'absolute',
                    top: '-0.15rem',
                    right: '-0.15rem',
                    bottom: '-0.15rem',
                    left: '-0.15rem',
                    borderRadius: '5px'
                  }}
                >
                  <chakra.p position="relative">{obj.content}</chakra.p>
                </Box>
              </Stack>
              <Stack direction="column" spacing={1} p={2} justify="flex-end" alignItems="center">
                <Avatar
                  size="lg"
                  showBorder={true}
                  borderColor="brand.secondary"
                  name="avatar"
                  src={obj.image}
                />
                <Box direction="column" textAlign="center">
                  <Text fontWeight="bold" fontSize="md">
                    {obj.name}
                  </Text>
                  <Text fontWeight="medium" fontSize="xs" color="gray.400">
                    {obj.position}, {obj.company}
                  </Text>
                </Box>
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
      </Container>
    );
  };
  
  export default Clients;
  