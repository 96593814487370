import React, { useEffect, useRef } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from "react-intersection-observer"
export const Animation = ({ children }) => {
    // const ref = useRef(null)
    const {ref,inView} = useInView()
    const mainControls = useAnimation()

    useEffect(() => {
        console.log("hello",inView)
        if (inView) {
            mainControls.start("visible")
        }
    }, [inView])
    return (
        <div ref={ref} style={{ position: 'relative',  overflow: 'hidden' }}>
            <motion.div
                variants={{
                    hidden: { opacity: 0, x: -200 },
                    visible: { opacity: 1, y: 0 },
                }}
                initiale='hidden'
                animate={inView?'visible':'hidden'}
                transition={{ duration: 0.5, delay: 0.25, ease: "easeInOut",
                scale: [1, 2, 2, 1, 1],
                rotate: [0, 0, 270, 270, 0],
                borderRadius: ["20%", "20%", "50%", "50%", "20%"],
            }}
            >
                {children}

            </motion.div>

        </div>
    )

}