import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  Image,
  useColorModeValue,
  useColorModeValue as mode
} from '@chakra-ui/react'
import * as React from 'react'
// import { HiOutlineExternalLink } from 'react-icons/hi'
import { SigninForm } from './SigninForm'
import EducationRed from "../../assets/images/educationred.svg"
import Education from "../../assets/images/rent2.svg"

export const Login = () => {
  return (
    <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }} as="section">
      <Flex flex={1} align={'center'} justify={'center'}  display={{base:'none', md: 'inline-flex' }} >
      <Flex flex={1} align={'center'} justify={'center'} maxW={'2xl'} rounded="lg"
      shadow="lg" m={5} >
        <Image
          alt={'Login Image'}
          objectFit={'cover'}
          src={Education}
        />
         </Flex>
      </Flex>
      <SigninForm />


    </Stack>
  )
}
