import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Container,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  useBreakpointValue,
  useDisclosure,
  Spacer,
  Heading
} from '@chakra-ui/react'
import * as React from 'react'
import { FiHelpCircle, FiSearch, FiSettings, FiUser } from 'react-icons/fi'
import { ColorModeSwitcher } from '../../ColorModeSwitcher'
import { Logo } from './Logo'
import LogoSA from './LogoSA'
import { Sidebar } from './Sidebar'
import { ToggleButton } from './ToggleButton'
import {Link} from "react-router-dom";


export const Navbar = () => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })
  const { isOpen, onToggle, onClose } = useDisclosure()
  return (
    <Box as="nav" bg="brand.primary" color="white" px={4} >
      <Flex minWidth='max-content' alignItems='center' gap='3'

        
      >
        {/* <Logo /> */}
      <Link to="/"><LogoSA/></Link>

        <Spacer />
        <HStack >
          {isDesktop && (
            <ButtonGroup variant="ghost-on-accent" spacing="1" >
              {/* <Link to="/"> 
              <Button>Home</Button>
              </Link>
              <Link to="/association"> 
              <Button aria-current="page">Associations</Button>
               </Link>
               <Link to="/donateur">
              <Button>Donateurs</Button>
              </Link> */}
            </ButtonGroup>
          )}
        </HStack>
        <Spacer />
        {isDesktop ? (
          <Box>
            <HStack spacing="4">
              <ButtonGroup variant="ghost-on-accent" spacing="1">
              <Link to="/login">
                <IconButton icon={<FiUser fontSize="1.25rem" />} aria-label="Search" />
                </Link>
                {/* <IconButton icon={<FiSettings fontSize="1.25rem" />} aria-label="Settings" /> */}
                <ColorModeSwitcher/> 
              </ButtonGroup>
              <Avatar boxSize="10" name="Christoph Winston" src="https://tinyurl.com/yhkm2ek8" />
            </HStack>
          </Box>
        ) : (
          <>
            <ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />
            <Drawer
              isOpen={isOpen}
              placement="left"
              onClose={onClose}
              isFullHeight
              preserveScrollBarGap
              // Only disabled for showcase
              trapFocus={false}
            >
              <DrawerOverlay />
              <DrawerContent>
                <Sidebar />
              </DrawerContent>
            </Drawer>
          </>
        )}
      </Flex>
    </Box>
  )
}
