import {
    useToast,
    useColorModeValue as mode
  } from '@chakra-ui/react'
  import * as React from 'react'
  import { useEffect, useContext } from 'react'
  import * as yup from "yup";
  import CRUD from '../composants/Crud';
  
  
  
  
  const Chambre = ({
    typeChambres,
    getChambres  }) => {
  
    const toast = useToast()
    // const [isUpdate, setisUpdate] = React.useState(false)
    const [typeChambre, setTypeChambre] = React.useState([])
    const [objetTypeChambre, setObjetTypeChambre] = React.useState({})
    const [state, setState] = React.useState({champsInitial:[],listeColumn:[] })
    const schema = yup.object().shape({
      code: yup.string().required("Le code est obligatoire"),
      libelle: yup.string().required("Le libelle est obligatoire"),
      type_chambre_id: yup.string().required("Le type de chambre est obligatoire"),
     
    });
    

  
    useEffect(() => {
      // setTypeChambre(typeChambres.map(item => ({
      //   key: item.id,
      //   value: item.libelle
      // })))
      // let obj={}
      // typeChambres.map(item=>
      //   {
      //     const id={}
      //     console.log("obj", item)
      //     id[`${item.id}`]=item.libelle
      //     obj=Object.assign(obj, id)
        
        
      // })
      // setObjetTypeChambre(obj)
     console.log("state", state)
  
    },[typeChambre])
    useEffect(() => {
       const tab = typeChambres.map(item => ({
        key: item.id,
        value: item.libelle
      }))
      console.log(tab)
      const champsInitial =
      [
        {
          libelle: null,
          icon: null,
          liste:
            [
              {
                label: "Libelle",
                placeholder: "Entrer le libelle",
                type: "text",
                name: 'libelle',
                isRequired: true
              },
              {
                label: "Code",
                placeholder: "Entrer le code ",
                type: "text",
                name: 'code',
                isRequired: true
              },
              {
                label: "Type chambre",
                placeholder: "Selectionner le type chambre",
                type: "select",
                name: 'type_chambre_id',
                liste:tab,
                isRequired: true
              }
  
            ]
        },
      ]
      setTypeChambre(tab)
      let obj={}
      typeChambres.map(item=>
        {
          const id={}
          console.log("obj", item)
          id[`${item.id}`]=item.libelle
          obj=Object.assign(obj, id)
        
        
      })
      const listeColumn = [
        {
          title: 'Code',
          field: 'code',
        },
        {
          title: 'Libelle',
          field: 'libelle',
        },
        {
          title: 'Type chambre',
          field: 'type_chambre_id',
          lookup: obj
        }
      ]
      setState({champsInitial:champsInitial, listeColumn:listeColumn})
      setObjetTypeChambre(obj)
    }, [typeChambres])
    return (
      <>
        <CRUD
          schema={schema}
          champs={state.champsInitial}
          columns={state.listeColumn}
          path="chambre"
          getData={getChambres}
          hasRightSave={true}
          hasRightDelete={true}
          hasRightUpdate={true}
          title="Chambre"
          icon={null}
        />
  
  
  
      </>
    )
  }
  export default Chambre
  