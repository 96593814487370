import { Box, Link, Stack, Button, IconButton, Center, Heading, LightMode, SimpleGrid, Text, Spacer } from '@chakra-ui/react'
import * as React from 'react'
import { FiCalendar, FiSend } from 'react-icons/fi'

export const Banner = () => {
  return (
    <Box
      as="section"
      bg="gray.800"
      py="12"
      position="relative"
      h={{ base: '560px', md: '800px' }}
      bgImage="url(https://images.unsplash.com/photo-1444201983204-c43cbd584d93?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&dl=markus-spiske-g5ZIXjzRGds-unsplash.jpg&w=1920)"
      bgSize="cover"
      bgPosition="center"
      _after={{
        content: `""`,
        display: 'block',
        w: 'full',
        h: 'full',
        bg: 'blackAlpha.700',
        position: 'absolute',
        inset: 0,
        zIndex: 0,
      }}
    >
      <Box
        maxW={{ base: 'xl', lg: '8xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
        h="full"
        zIndex={1}
        position="relative"
      >
        <Center flexDirection="column" textAlign="center" color="white" h="full">
          
          <Heading size={{ base: 'lg', md: '4xl' }} fontWeight="extrabold" >
            <h1>Arlette et Axelle sista  </h1>
          </Heading>
          <Box bg="brand.primary" p={5}>
          <Heading size={{ base: 'lg', md: '4xl' }}  fontWeight="extrabold" >
            <h1>Hébergement - Restauration</h1>
          </Heading>
          </Box>
          <LightMode>
            <Stack
              spacing="4"
              pt={5}
              direction={{
                base: 'column',
                lg: 'row',
              }}
              justify="space-between"
            >
              <Box>
                <Link to="/donateur">
                  <Button
                    size={{ base: "sm", md: "lg" }}
                    w="full"
                    leftIcon={<FiCalendar />}
                    textTransform="uppercase"
                    color="brand.primary" bg={"white"}>Faire une reservation</Button>
                </Link>
              </Box>
              {/* <Spacer />
              <Box>
                <Link to="/association">
                  <Button
                    size={{ base: "sm", md: "md" }}
                    w="full"
                    leftIcon={<FiSend />}
                    textTransform="uppercase"
                    bg="bleu.900" color={"white"}>Trouver une opportunité</Button>
                </Link>
              </Box> */}
              
              
            </Stack>
          </LightMode>
        </Center>
      </Box>
      <Box
        display={{ base: 'block', md: 'block' }}
        position="absolute"
        zIndex={2}
        w="full"
        bottom="0"
        py="4"
        bg="whiteAlpha.400"
      >
        <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto">
          <SimpleGrid columns={{ base: 1, md: 4 }}>
            <Box textAlign="center" color="white">
              <Text fontSize="2xl" color="brand.primary" fontWeight={"bold"}>Chambre Simple</Text>
              <Text fontSize="3xl">12</Text>
            </Box>
            <Box textAlign="center" color="white">
            <Text fontSize="2xl" color="brand.primary" fontWeight={"bold"}>Chambre VIP</Text>
              <Text fontSize="3xl">10</Text>
            </Box>
            <Box textAlign="center" color="white">
            <Text fontSize="2xl" color="brand.primary" fontWeight={"bold"}>Suite</Text>
              <Text fontSize="3xl">6</Text>
            </Box>
            <Box textAlign="center" color="white">
            <Text fontSize="2xl" color="brand.primary" fontWeight={"bold"}>Appartement</Text>
              <Text fontSize="3xl">4</Text>
            </Box>
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  )
}