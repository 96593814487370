import React from 'react'
import { Flex, Stack, Center, useColorModeValue, Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react'

function DataTabs({ data }) {
  const bg = useColorModeValue('brand.primary', 'brand.primary')
  return (
    <Box
      minW={{ base: "100%", md: "100%", lg: "90%" }}
      mx={{ base: "1", md: "2%", lg: "2%" }}
      borderWidth='2px'
      borderRadius='lg'
      borderColor="brand.primary"
    
    >
      <Tabs
        isFitted variant='enclosed'
        
      >
        <TabList>
          {data.map((tab, index) => (
            <Tab key={index} _selected={{ color: 'white', bg: bg }}
            size={{ base: "xs", md: "lg" }}
            p={{ base: 1, md: 3 }}
            >{tab.label}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {data.map((tab, index) => (
            <TabPanel key={index}>
              {tab.content}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default DataTabs