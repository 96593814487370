import { Box, Container, Text, Stack, Button } from '@chakra-ui/react'
import * as React from 'react'
import { Banner } from './banner'
import { Navbar } from './Navbar'
import { Link } from "react-router-dom";

export const HomeSista = () => (

  <Box as="section" height="full" overflowY="auto">
    <Navbar />
    <Banner />
    {/* <Container
      pt={{
        base: '8',
        lg: '12',
      }}
      pb={{
        base: '5',
        lg: '5',
      }}
      minW={{
        base: '100%',
        md: '80%',
      }}
    >
      <Stack
        spacing="4"
        direction={{
          base: 'column',
          lg: 'row',
        }}
        justify="space-between"
      >
        <Box>
          <Stack
            spacing="4"
            direction={{
              base: 'column',
              lg: 'column',
            }}
          >

            <Text fontSize="lg" fontWeight="medium" mt="3">
              Je suis une Organisation de la Société Civile
            </Text>
            <Link to="/registration">
              <Button
                size={{ base: "sm", md: "md" }}
                w="full"
                leftIcon={<FiSend />}
                textTransform="uppercase"
                bg="brand.primary" color={"white"}>J'inscris mon organisation</Button>
            </Link>
          </Stack>
        </Box>

        <Box>
          <Stack
            spacing="4"
            direction={{
              base: 'column',
              lg: 'column',
            }}
            align="center"
          >
            <Text fontSize="lg" fontWeight="medium" mt="3">
              Je suis un membre de l’Alliance Sahel
            </Text>
            <Link to="/donateur">
              <Button
                size={{ base: "sm", md: "md" }}
                w="full"
                leftIcon={<FiSend />}
                textTransform="uppercase"
                bg="bleu.900" color={"white"}>Je soumets une opportunité de financement</Button>
            </Link>
          </Stack>
        </Box>
      </Stack>
      <Content />
    </Container>
    <ListeADonateur pays={"Opportunités de financement existantes"} disabled={false}/>
    <Description />
    <ListeAssociation pays={"Projets recents financés "} disabled={true}/>
    {/* <Box>
      {true && (
        <Button bg="bleu.900" size="lg" color={"white"}>
          <Link to="donateur">

            Voir toutes les opportunités existantes

          </Link>
        </Button>
      )}
    </Box> */} 

{/*    

    <Footer /> */}

  </Box>

)
