import React, { Component } from "react";

import { extendTheme } from "@chakra-ui/react"
import { ChakraProvider } from "@chakra-ui/react"
import {  GlobalProvider } from "./context";
import RouteApp from "./route";
// 2. Update the breakpoints as key-value pairs



const colors = {
  brand: {
    darkGrey: "#595959",
    ligthGrey:"8F8F8F",
    white: "#FFFFFF",
    primary:'#410737',
    secondary:"#9E9C09",
    gray:"#D6D6D6",
    blue:"#4BB4E6",
    green:"#50BE87",
    purple:"#A885D8",
    darkWhite:"#ECECEC",
    ligthGray:"#F8F8FA"
  },
  fonts: {
    heading: "Poppins",
    body: "poppins",
  },
}
const breakpoints = {
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
}

// 3. Extend the theme
// const theme = extendTheme({ breakpoints })
const theme = extendTheme({ colors ,breakpoints })

class App extends Component {
  render() {
    return (
      <>
      <ChakraProvider theme={theme}>
          <GlobalProvider >
          <RouteApp />
        </GlobalProvider>
      </ChakraProvider>
      </>
      
    );
  }
}

export default App;

