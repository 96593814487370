import { FormErrorMessage, useDisclosure, Box, FormControl, Textarea, FormLabel, Input, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'
import EditorField from './EditorField'

export const TextAreaField = React.forwardRef((props, ref) => {
  const { label, errors, values, reference, name, ...rest } = props
  const [value, setValue] = React.useState("")
  const { isOpen, onOpen, onClose } = useDisclosure()
  React.useEffect(() => {
    console.log("value====>", value)
  }, [value])
  React.useEffect(() => {
    setValue(values[name])
  }, [values[name]])

  return (
    <>
      <FormControl
        onClick={onOpen}
        position="relative" isInvalid={errors[name]} size="100%">
        <FormLabel
          _focus={{
            color: mode('brand.primary', 'brand.primary'),
          }}
          fontWeight="semibold"
          position="absolute"
          color={mode('gray.600', 'white')}
          top="-7"
          insetStart="2"
          // bg={{
          //   base: mode('white', 'gray.800'),
          //   md: mode('white', 'gray.700'),
          // }}
          zIndex={2}
          px="2"
          fontSize={{ base: "sm", md: "md" }}
        >
          {label}
        </FormLabel>
        <Textarea
          // isDisabled
          display={"none"}
          defaultValue={values[name]}
          value={value}
          ref={reference} size="md" focusBorderColor="brand.secondary" fontSize={{ base: "sm", md: "md" }} {...rest} name={name} />
        <FormErrorMessage>
          {errors[name]?.message}
        </FormErrorMessage>
      </FormControl>
      <Box
        minH={"400px"}
        minW="100%"
        border={2}
      >
        <EditorField
          isOpen={true}
          onClose={onClose}
          defaultValue={values[name]}
          getText={setValue}
        />
      </Box>
    </>
  )
})
TextAreaField.displayName = 'TextAreaField'
